






















































import Vue from "vue";
import { AuthLogin } from "@/interfaces/auth/auth_login";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  AUTH_LOADING,
  GET_USER_DETAILS,
  LOGIN_USER,
  USER_STATE
} from "@/store/modules/auth/constants";
import {
  GET_ROLE_BY_NAME,
  INIT_COMMON_DATA
} from "@/store/modules/common/constants";
import { INIT_RECRUITER } from "@/store/modules/recruiter/constants";
import { email, required } from "vuelidate/lib/validators";
import { TranslateResult } from "vue-i18n";
import { UserState } from "@/store/modules/auth/interfaces";
import router from "@/router";
import { INIT_CANDIDATE } from "@/store/modules/candidates/constants";
import { ROOT_ERROR } from "@/store/modules/root/constants";
export default Vue.extend({
  name: "AuthLogin",
  data(): AuthLogin {
    return {
      login_form: {
        email: ""
      },
      show_password: false
    };
  },
  computed: {
    ...mapGetters("auth", {
      user_data: GET_USER_DETAILS,
      auth_loading: AUTH_LOADING,
      get_user_state: USER_STATE
    }),
    email_field_errors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.login_form.email?.$dirty) return errors;
      !this.$v.login_form.email?.required &&
        errors.push(this.$t("rules.required"));
      !this.$v.login_form.email?.email && errors.push(this.$t("rules.email"));
      return errors;
    },
    // password_field_errors(): TranslateResult[] {
    //   const errors: TranslateResult[] = [];
    //   if (!this.$v.login_form.password?.$dirty) return errors;
    //   !this.$v.login_form.password?.required &&
    //     errors.push(this.$t("rules.required"));
    //   !this.$v.login_form.password?.min &&
    //     errors.push(this.$t("rules.password.min"));
    //   !this.$v.login_form.password?.max &&
    //     errors.push(this.$t("rules.password.max"));
    //   !this.$v.login_form.password?.valid &&
    //     errors.push(this.$t("rules.password.combine"));
    //   return errors;
    // },
    ...mapGetters("common", {
      get_role_by_name: GET_ROLE_BY_NAME
    }),
    ...mapGetters("auth", {
      get_user: GET_USER_DETAILS
    })
  },
  methods: {
    ...mapMutations({
      root_error: ROOT_ERROR
    }),
    ...mapMutations("auth", {
      set_user_state: USER_STATE,
      set_auth_loading: AUTH_LOADING,
      update_user_state: USER_STATE
    }),
    ...mapActions("auth", {
      login_user: LOGIN_USER
    }),
    ...mapActions("recruiter", {
      init_recruiter: INIT_RECRUITER
    }),
    ...mapActions("candidate", {
      init_candidate: INIT_CANDIDATE
    }),
    ...mapActions("common", {
      init_common_data: INIT_COMMON_DATA
    }),
    /**
     * FUnction to initialize reset password state
     */
    async reset_password_init() {
      this.set_user_state(UserState.RESET_PASSWORD);
      await router.push("reset-password");
    },
    async submit_login_form() {
      this.$v.$touch();
      // Validating login form
      if (!this.$v.$invalid) {
        const login_payload = { ...this.login_form };
        // Login user
        const login_response = await this.login_user(login_payload);
        // If login successful
        if (login_response) {
          this.$v.login_form?.$reset();
          if (!this.get_user.is_onboarding_completed) {
            this.update_user_state(UserState.ONBOARDING_PENDING);
            await router.push("/onboarding");
          } else if (this.get_user.roleDetail.role_name === "candidate") {
            this.update_user_state(UserState.CANDIDATE);
            router.push("/candidate");
          } else if (this.get_user.roleDetail.role_name === "recruiter") {
            this.update_user_state(UserState.RECRUITER);
            router.push("/recruiter");
          }
          this.set_auth_loading(false);
        }
      }
    }
  },
  // Form validations
  validations() {
    return {
      login_form: {
        email: {
          required,
          email: email
        }
      }
    };
  }
});
